<template>
  <div class="tempRegister">
    <v-container>
      <div>
        <v-toolbar-title class="ms-3 mt-10 pt-10 mb-5 text-center">
          <span >ー パスワードのリセット ー</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mb-1 pb-5 px-5">
          <p>
            {{ $t("message.label-desc-forgot-pass") }}
          </p>
          <p class="mt-3">
             {{ $t("message.label-url-reset-pass") }}
          </p>
        </div>
        <form class="px-5" @submit.prevent="submitEmail">
          <v-row>
            <v-col
              cols="12"
              md="2"
              xs2
              class="label-class"
              style="padding-bottom: 0px"
            >
              <span>{{ $t("message.label-email") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs2 class="input-class">
              <v-text-field
                outlined
                background-color="white"
                v-model="email"
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                required
              />
            </v-col>
          </v-row>
          <div class="d-flex flex-column align-center mt-3 me-0" tile>
            <div class="m-2">
              <v-btn
                depressed
                color="#ff0090"
                class="white--text"
                width="200"
                type="submit"
                @click.prevent="submitEmail"
                :loading="isLoadingBtn"
                :disabled="isLoadingBtn"
              >
                {{ $t("message.btn-send") }}
              </v-btn>
            </div>
            <div class="m-2 mt-5">
              <v-btn @click="$router.push('/login')" text color="#ff0090">
                {{ $t("message.btn-back-to-login") }}
              </v-btn>
            </div>
          </div>
        </form>
      </div>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import LoadingPage from "../components/LoadingPage.vue";
import { mdiCloseThick } from "@mdi/js";
import Swal from "sweetalert2";
export default {
  name: "ForgotPassword",
  mixins: [validationMixin],
  components: {
    LoadingPage,
  },
  validations: {
    email: { required, email },
  },
  data() {
    return {
      closePage: mdiCloseThick,
      email: null,
      err: null,
      isLoading: false,
      isLoadingBtn: false,
    };
  },
  methods: {
    async submitEmail() {
      this.isLoadingBtn = true;
      if (!this.email) {
        Swal.fire("Email is required");
        return false;
      } else {
        let payload = {
          email: this.email,
          url_redirect:
            window.location.origin + process.env.BASE_URL + "resetpassword",
        };
        await this.$store.dispatch("auth_module/cekEmail", payload);
        this.isLoadingBtn = false;
      }
    },
  },
  computed: {
    emailErrors() {
      let errors = [];
      if (!this.$v.email.$dirty) return errors;
      // !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.email && errors.push(this.$t("message.err-msg-email"));
      !this.$v.email.required &&
        errors.push(this.$t("message.err-msg-email-required"));
      this.err = errors;
      return errors;
    },
  },
};
</script>

<style>
.tempRegister {
  background-color: #e0e0e0;
  height: 100vh;
  text-align: justify;
  text-justify: inter-word;
  max-width: 100vw !important;
}
</style>
